/**
 * 指定した位置まで要素がスクロールした時に、classを追加する
 *
 * @param selector クラスを追加したい要素
 * @param className 追加したいクラス名
 */

export function toggleClassWithScroll(target, selector, className, ) {
    let elements = document.querySelectorAll(selector);
    let targets = document.querySelectorAll(target);
    Array.prototype.forEach.call(elements, function (element, index) {
        // windowの高さ - a
        let windowHeight = window.document.documentElement.clientHeight;
        // 現在のスクロール量
        let scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        // 画面左上から要素までの高さ - b
        let rect = element.getBoundingClientRect();
        let offset = rect.top + scrollTop;
        // 要素が画面に表示されるスクロール量(a - b)
        let scrollHeight = offset - windowHeight;
        // 画面の1/4を超えたら、classを追加
        if (scrollTop > scrollHeight + windowHeight / 4) {
            targets.forEach((target) => {
                target.classList.add(className);
            });
        } else {
            targets.forEach((target) => {
                target.classList.remove(className);
            });
        }
    });
}

export function addClassWithScroll(target, selector, className, perNum) {

    let elements = document.querySelectorAll(selector);
    let targets = document.querySelectorAll(target);
    Array.prototype.forEach.call(elements, function (element, index) {
        // windowの高さ - a
        let windowHeight = window.document.documentElement.clientHeight;

        // 現在のスクロール量
        let scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        // 画面左上から要素までの高さ - b
        let rect = element.getBoundingClientRect();
        let offset = rect.top + scrollTop;
        // 要素が画面に表示されるスクロール量(a - b)
        let scrollHeight = offset - windowHeight;

        // 画面の1/perNumを超えたら、classを追加
        if (scrollTop > scrollHeight + windowHeight / perNum) {
            targets.forEach((target) => {
                target.classList.add(className);
            });
        }
    });
}

export function addClassWithScrollMultipleClass(selector, className, perNum) {

  let elements = document.querySelectorAll(selector);
  Array.prototype.forEach.call(elements, function (element, index) {
      // windowの高さ - a
      let windowHeight = window.document.documentElement.clientHeight;

      // 現在のスクロール量
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      // 画面左上から要素までの高さ - b
      let rect = element.getBoundingClientRect();
      let offset = rect.top + scrollTop;
      // 要素が画面に表示されるスクロール量(a - b)
      let scrollHeight = offset - windowHeight;

      // 画面の1/perNumを超えたら、classを追加
      if (scrollTop > scrollHeight + windowHeight / perNum) {
        element.classList.add(className);
      }
  });
}
