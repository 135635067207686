"use strict";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { EaselPlugin } from "gsap/EaselPlugin";
import { addClassWithScrollMultipleClass as scroll } from "./common/_scroll-event";
import smoothscroll from "smoothscroll-polyfill";
require("intersection-observer");

gsap.registerPlugin(EaselPlugin, ScrollTrigger);

const mediaQuery = matchMedia("screen and (min-width: 768px)");
changeMediaQuery(mediaQuery);
// ウィンドウサイズが変更されても実行されるように
mediaQuery.addListener(changeMediaQuery);



/**
 * スクロールイベント登録
 */
let scrollY;
let fixPoint ;
window.addEventListener(
  "scroll",
  () => {
    scrollY = window.pageYOffset;
    fixPoint = document.querySelector(".fixed-menu").getBoundingClientRect().top + scrollY;
    setFixPoint();
    scroll(".fadein", "on", 4);
  },
  false
);


/**
 * リサイズイベント登録
 */
window.addEventListener("resize", function () {}, false);

function changeMediaQuery(mq) {
  if (mq.matches) {
    // PC
    document.body.classList.add("is-pc");
    document.body.classList.remove("is-sp");
    // グロナビ外をクリックしたら閉じる
    document
      .querySelector(".header-list__blank")
      .addEventListener("click", (e) => {
        document.querySelector(".header-menu").click();
      });
  }
  else {
    // SP
    document.body.classList.add("is-sp");
    document.body.classList.remove("is-pc");
    // kv
    // スクロールするとvhが変わってしまう問題を解決
    changeKvHeight();
    window.addEventListener("resize", changeKvHeight);
  }
}

// グロナビ 展開
document.querySelectorAll(".js-header-btn").forEach((target) => {
  target.addEventListener("click", (e) => {
    const thisBtn = e.currentTarget;
    thisBtn.classList.toggle("is-active");
    let header = document.querySelector(".js-header");
    header.classList.toggle("is-active");
    scrollLock();
    if (header.classList.contains("is-active")) {
      document.querySelector(".header-menu__txt").innerHTML = "CLOSE";
    }
    else {
      document.querySelector(".header-menu__txt").innerHTML = "MENU";
    }
  });
});
//グロナビ押したら閉じる
document.querySelectorAll(".glonav-menu__link").forEach((target) => {
  target.addEventListener("click", (e) => {
    document.querySelector(".js-header").classList.remove("is-active");
    document.querySelector(".js-header-btn").classList.toggle("is-active");
    document.querySelector(".header-menu__txt").innerHTML = "MENU";
    scrollLock();
  });
});

let currentY;
function scrollLock() {
  if (document.body.classList.contains("is-scroll-locked")) {
    document.body.style.top = "";
    document.body.classList.remove("is-scroll-locked");
    window.scrollTo(0, currentY);
    return false;
  }

  currentY = document.documentElement.scrollTop || document.body.scrollTop;

  // メニューを開いたときにスクロール位置が一番上に戻らないように
  document.body.style.top = `-${currentY}px`;
  document.body.classList.add("is-scroll-locked");
}

function changeKvHeight() {
  document.querySelectorAll(".js-height").forEach((target) => {
    target.style.height = `${window.outerHeight}px`;
  });
}

// スムーススクロール
smoothscroll.polyfill();

document.querySelectorAll(`a[href*="#"]`).forEach((link) => {
  link.addEventListener("click", (e) => {
    const target = e.currentTarget;
    e.preventDefault();
    const targetId = target.hash;
    var href = target.href;
    var currentURL = location.href;
    if(currentURL.indexOf("#") > -1)  currentURL = currentURL.split("#")[0];
    var param = href.substring(href.indexOf("?"));

    if(target.hasAttribute("target") && target.getAttribute("target") == "_blank") {
      open( target.href, "_blank" ) ;
    }
    else {
      if (href.indexOf("?") > -1) {
        location.href = href.replace(param, "");
      }
      else {
        if(href.indexOf(currentURL) == -1 || location.pathname == "/") {
          location.href = href;
        }
        else if(href.indexOf(currentURL) > -1) {
          const targetElement = document.querySelector(targetId);
          // 画面上部から要素までの距離
          const rectTop = targetElement.getBoundingClientRect().top;
          // 現在のスクロール距離
          const offsetTop = window.pageYOffset;
          //スクロール位置に持たせるバッファ
          const top = rectTop + offsetTop;

          window.scrollTo({
            top,
            behavior: "smooth",
          });
        }
      }
    }
  });
});



// IEでスムーススクロールを無効に
if (document.documentMode) {
  document.querySelector(`body`).addEventListener("mousewheel", (event) => {
    event.preventDefault();
    let wd = event.wheelDelta;
    let csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}

const hoverTrigger = document.querySelectorAll(".header-fix-list__ttl");
for (let i = 0; i < hoverTrigger.length; i++) {
  hoverTrigger[i].addEventListener("mouseenter", function (e) {
    for (let j = 0; j < hoverTrigger.length; j++) {
      if (hoverTrigger[i] != hoverTrigger[j])
        hoverTrigger[j].classList.add("header-fix-list__ttl--gray");
    }
  });
  hoverTrigger[i].addEventListener("mouseleave", function (e) {
    for (let j = 0; j < hoverTrigger.length; j++) {
      if (hoverTrigger[i] != hoverTrigger[j])
        hoverTrigger[j].classList.remove("header-fix-list__ttl--gray");
    }
  });
}

/**
 * アコーディオン
 *
 */

const accordionTrigger = document.querySelectorAll(".js-accordion");
for (let i = 0; i < accordionTrigger.length; i++) {
  // トリガーを押した時のアクション
  let accordionTargetisOpened = false;
  accordionTrigger[i].addEventListener("click", function (e) {
    // クリックされた要素（トリガー要素）を取得
    let currentElement = e.currentTarget;
    let accordionTarget = currentElement.nextElementSibling;
    // 同じ親要素を持つ隣接した次の要素'.js-accordion'（展開対象の要素）を取得
    if (currentElement.classList.contains("accordion-ancestor")) {
      accordionTarget = currentElement.parentNode.nextElementSibling;
    }

    if (accordionTargetisOpened) {
      //トリガーの'is-active'クラスを削除
      currentElement.classList.remove("is-active-trigger");
      accordionTarget.classList.remove("is-active");
      accordionTargetisOpened = false;
    } else {
      //トリガーの'is-active'クラスを追加
      currentElement.classList.add("is-active-trigger");
      accordionTarget.classList.add("is-active");
      accordionTargetisOpened = true;
    }
  });
}

let cvItem = document.querySelectorAll(".cv-area__item");
function setFixPoint() {
  let cvArea = document.querySelector(".cv-area__inner").clientHeight;
  let headerMenu = document.querySelector(".header-menu");
  let unfixPoint = document.querySelector(".unfixed-menu").getBoundingClientRect().top + scrollY;
  if(unfixPoint < (scrollY + window.innerHeight)) {
    for (let i = 0; i < cvItem.length; i++) {
      if(!cvItem[i].classList.contains("cv-area__scroll")) cvItem[i].classList.add("cv-area__scroll");
    }
    gsap.to('.cv-area__item',{duration: 0.25, x: 100}, 0);
  }

  else if (scrollY > (fixPoint - cvArea)) {
    headerMenu.classList.add("fix");
    for (let i = 0; i < cvItem.length; i++) {
      cvItem[i].classList.add("cv-area__scroll");
    }
    gsap.to('.cv-area__item',{duration: 0.25, x: 0}, 0);
  }
  else {
    headerMenu.classList.remove("fix");
    for (let i = 0; i < cvItem.length; i++) {
      cvItem[i].classList.remove("cv-area__scroll");
    }
  }
}


for (let i = 0; i < cvItem.length; i++) {
  cvItem[i].addEventListener("mouseenter", function (e) {
    if(scrollY > fixPoint) {
      if(window.innerWidth > 768) {
        cvItem[i].classList.remove("cv-area__scroll");
      }
    }
  });
  cvItem[i].addEventListener("mouseleave", function (e) {
    if(scrollY > fixPoint) {
      if(window.innerWidth > 768) {
        cvItem[i].classList.add("cv-area__scroll");
      }
    }
  });
}

const navItem = document.querySelectorAll(".flex-container-contents__item");
for (let i = 0; i < navItem.length; i++) {
  navItem[i].addEventListener("mouseenter", function (e) {
    document.querySelector(".flex-container-contents__inner").classList.add("hover");
  });
  navItem[i].addEventListener("mouseleave", function (e) {
    document.querySelector(".flex-container-contents__inner").classList.remove("hover");

  });
}

//目次

const boxes = document.querySelectorAll(".content-trigger");

const options = {
  root: null,
  rootMargin: "-50% 0px",
  threshold: 0
};
const observer = new IntersectionObserver(doWhenIntersect, options);
boxes.forEach(box => {
  observer.observe(box);
});

/**
 * 交差したときに呼び出す関数
 * @param entries
 */
function doWhenIntersect(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activateIndex(entry.target);
    }
  });
}

/**
 * 目次の色を変える関数
 * @param element
 */
function activateIndex(element) {
  const currentActiveIndex = document.querySelector(".flex-container-contents__item.is-active");
  if (currentActiveIndex !== null) {
    currentActiveIndex.classList.remove("is-active");
  }
  const newActiveIndex = document.querySelector(
    `a[href='#${element.id}']`
  );
  newActiveIndex.parentNode.classList.add("is-active");

}
